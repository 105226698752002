<template>

  <div class="mx-4 my-4">
    <div class="text-h4 mb-8" >Settings</div>
    <v-tabs vertical background-color="grey lighten-2" style="border:1px solid #dddddd ;border-radius: 6px;">
      <v-tab >
        <v-icon left>
          mdi-label-multiple-outline
        </v-icon>
        Column Groups
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-tag-outline
        </v-icon>
        Variant Labels
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-table-headers-eye
        </v-icon>
        Panel Setting
      </v-tab> 
      <v-tab>
        <v-icon left>
          mdi-office-building
        </v-icon>
        Organizations
      </v-tab>               
      <v-tab-item>
        <ColumnGroups></ColumnGroups>
      </v-tab-item>
      <v-tab-item>
        <VariantLabels></VariantLabels>
      </v-tab-item>
      <v-tab-item>
        <PanelSettings></PanelSettings>
      </v-tab-item>
      <v-tab-item>
        <Organizations></Organizations>
      </v-tab-item>
    </v-tabs>
  </div>
  <!-- <v-app>
    <v-container fluid  class="px-8" >
      <router-view></router-view>
    </v-container>
  </v-app> -->
</template>

<script>
import VariantLabels from '@/views/admin/settings/VariantLabels.vue'
import ColumnGroups from '@/views/admin/settings/ColumnGroups.vue'
import PanelSettings from '@/views/admin/settings/PanelSettings.vue'
import Organizations from '@/views/admin/settings/Organizations.vue';
export default {
  name: 'Setting',
  components: {VariantLabels, ColumnGroups, PanelSettings,Organizations}

}
</script>