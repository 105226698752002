<template>
<div class=" mt-5 mx-10" >
  <v-data-table
    :headers="headers"
    :items="organizations"
    sort-by="name"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar  elevation="0" >
        <!-- <v-toolbar-title>Variant Labels </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical ></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary"  outlined small  class="mb-2" v-bind="attrs" v-on="on"  >
              New Organization
            </v-btn>
          </template>
           <v-form ref="form" v-model="valid" lazy-validation >
          <v-card>
            <v-card-title class="accent">
              <span  class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col  >
                    <v-text-field :rules="[ v => !!v ||'Name is required!']"  v-model="selectedOrganization.name" label="Organization" ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close" >
                Cancel
              </v-btn>
              <v-btn :disabled="!valid" color="blue darken-1" text @click="save" >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
           </v-form>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="accent">
              <span  class="text-h5">Delete Organization </span>
            </v-card-title>
            <v-card-text class="body-1 mt-6 ">Deleting this label will NOT result is any changes in the annotaions made by users! Are you sure you want to delete <b>{{selectedOrganization.name}}</b>?</v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="red darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on }">
            <v-btn
              icon

              color="primary"
              @click="editItem(item)"
              v-on="on"
            >
              <v-icon class="mr-2">mdi-file-edit-outline</v-icon>
            </v-btn>
        </template>
        <span>Edit {{item.name}}</span>
      </v-tooltip>
      <v-tooltip top color="orange">
        <template v-slot:activator="{ on }">
            <v-btn
              icon
              large
              color="orange"
              @click="deleteItem(item)"
              v-on="on"
            >
              <v-icon class="mr-2">mdi-delete-forever</v-icon>
            </v-btn>
        </template>
        <span>Delete {{item.name}}</span>
      </v-tooltip>
    </template>
  </v-data-table>
</div>
</template>

<script>
  import { getOrganizations, addOrganization } from '@/api/settings.js'
  export default {
    data: () => ({
      valid: true,
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'NAME', align: 'start', value: 'name' ,class:"accent h2"},
        // { text: 'ACTIONS', align: 'end', value: 'actions', sortable: false ,class:"accent"},
      ],
      organizations: [],
      editedIndex: -1,
      selectedOrganization: {
        id: -1,
        name: '',
      },
      defaultItem: {
        id: -1,
        name: '',
      },
    }),

    computed: {
      formTitle () {
        return this.selectedOrganization.id === -1 ? 'New Organization' : 'Edit Organization'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      loadOrganizations(){
        const _this = this
        getOrganizations(function(res){
          _this.organizations = res
        })
      },
      initialize () {
        this.loadOrganizations()
      },

      editItem (item) {
        this.selectedOrganization =  Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.selectedOrganization =  Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        // const _this = this
        // deleteVariantLabel(this.selectedOrganization.id,function(res){
        //   console.log(res)
        //   _this.loadOrganizations()
        // }, 'Label ' + _this.selectedOrganization.title + " removed successfully" )
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.selectedOrganization = Object.assign({}, this.defaultItem)
          //this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.selectedOrganization = Object.assign({}, this.defaultItem)
          //this.editedIndex = -1
        })
      },

      save () {
        var isValidated = this.$refs.form.validate()
        if (isValidated){
          if (this.selectedOrganization.id > -1) {
            // const _this = this
            // editVariantLabel(this.selectedOrganization.id, this.selectedOrganization, function(res){
            //   console.log(res)
            //   _this.loadOrganizations()
            // }, 'Group ' + _this.selectedOrganization.title +  ' updated successfully'
            // )
          } else {
            const _this = this
            addOrganization(this.selectedOrganization, function(){
              _this.loadOrganizations()
            }, 'Group ' + _this.selectedOrganization.name +  ' added successfully'
            )
          }
          this.close()
        }
      },
    },
  }
</script>