<template>
<div class=" mt-5 mx-10" >
    <v-dialog v-model="dialog" max-width="600px" >
           <v-form ref="form" v-model="valid" lazy-validation >
          <v-card>
            <v-card-title class="accent">
              <span  class="text-h5">Edit Setting</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col  >
                    <v-text-field v-model="selectedPanelSetting.value" label="Value" ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close" >
                Cancel
              </v-btn>
              <v-btn :disabled="!valid" color="blue darken-1" text @click="save" >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
           </v-form>
        </v-dialog>
  <v-data-table
    :headers="headers"
    :items="panelSettings"
    sort-by="id"
    class="elevation-1"
  >

    <template v-slot:item.actions="{ item }">
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="primary"
              @click="editItem(item)"
              v-on="on"
            >
              <v-icon class="mr-2">mdi-file-edit-outline</v-icon>
            </v-btn>
        </template>
        <span>Edit {{item.id}}</span>
      </v-tooltip>
    </template>
  </v-data-table>
</div>
</template>

<script>
  import { listPanelSettings, editPanelSettings } from '@/api/settings.js'
  export default {
    data: () => ({
      valid: true,
      dialog: false,
      headers: [
        { text: 'NAME', align: 'start', value: 'id' ,class:"accent h2"},
        { text: 'VALUE', align: 'start', value: 'value' ,class:"accent h2"},
        { text: 'ACTIONS', align: 'end', value: 'actions', sortable: false ,class:"accent"},
      ],
      panelSettings: [],
      editedIndex: -1,
      selectedPanelSetting: {
        id: -1,
        title: '',
      },
      defaultItem: {
        id: -1,
        title: '',
      },
    }),

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
        this.loadPanelSettings()
    },

    methods: {
      loadPanelSettings(){
        const _this = this
        listPanelSettings(function(res){
          _this.panelSettings = res
        })
      },

      editItem (item) {
        this.selectedPanelSetting =  Object.assign({}, item)
        this.dialog = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.selectedPanelSetting = Object.assign({}, this.defaultItem)
          //this.editedIndex = -1
        })
      },

      save () {
        var isValidated = this.$refs.form.validate()
        if (isValidated){
            const _this = this
            editPanelSettings(this.selectedPanelSetting.id, this.selectedPanelSetting, function(){
              _this.loadPanelSettings()
            }, 'Setting ' + _this.selectedPanelSetting.id +  ' updated successfully'
            )
        
          this.close()
        }
      },
    },
  }
</script>