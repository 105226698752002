import ApiIntegrface from "./api"
export function getFieldGroups(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/fieldgroups'
  api.get(url, null, callback, null, null, null)
}

export function addFieldGroup(payload, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/fieldgroups'
  api.post(url, payload, callback, null, successMessage)
}

export function editFieldGroup(id, payload, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/fieldgroups/' + id
  api.put(url, payload, callback, null, successMessage)
}


export function deleteFieldGroup(groupId, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/fieldgroups/' + groupId
  api.delete(url, null, callback, null, successMessage)
}

export function getVariantLabels(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/variantannotationlabels'
  api.get(url, null, callback, null, null, null)
}

export function addVariantLabel(payload, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/variantannotationlabels'
  api.post(url, payload, callback, null, successMessage)
}


export function getOrganizations(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/organizations'
  api.get(url, null, callback, null, null, null)
}

export function addOrganization(payload, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/organizations'
  api.post(url, payload, callback, null, successMessage)
}

export function editVariantLabel(id, payload, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/variantannotationlabels/' + id
  api.put(url, payload, callback, null, successMessage)
}


export function deleteVariantLabel(groupId, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/variantannotationlabels/' + groupId
  api.delete(url, null, callback, null, successMessage)
}

export function listPanelSettings(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/settings'
  api.get(url, null, callback, null, null, null)
}

export function getPanelSettings(id,callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/settings/'+id
  api.get(url, null, callback, null, null, null)
}


export function editPanelSettings(id, payload, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/settings/' + id
  api.put(url, payload, callback, null, successMessage)
}