<template>
  <div class="mt-8 mx-8">
    <v-dialog v-model="dialog" max-width="600px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="accent">
            <span class="text-h5"> Upload Annotation</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  Zip archive containing the following COMMA separated files:
                  <div>
                    <ul>
                      <li>genes.csv</li>
                      <li>enhancers.csv</li>
                      <li>tads.csv</li>
                      <li>connections.csv</li>
                      <li>dynamics.csv</li>
                    </ul>
                </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <VueFileAgent
                    ref="vueFileAgent"
                    :theme="'list'"
                    :multiple="true"
                    :deletable="true"
                    :meta="true"
                    :accept="'.zip'"
                    :maxSize="'1400MB'"
                    :maxFiles="1"
                    :helpText="'Click here to choose your annotation file or drag it here.'"
                    :errorText="{
                      type: 'Invalid file type. Only zip Allowed',
                      size: 'Files should not exceed 1400MB in size',
                    }"
                    @select="filesSelected($event)"
                    @beforedelete="onBeforeDelete($event)"
                    @delete="fileDeleted($event)"
                    @upload="onUploaded($event)"
                    @upload:error="onUploadError($event)"
                    v-model="fileRecords"
                  ></VueFileAgent>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="body-2 orange--text">
                  {{ errorMessage }}
                </v-col>
              </v-row>
            </v-container>
            
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn :disabled="!fileRecordsForUpload.length" color="primary " @click="uploadFiles"> Upload </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-data-table :headers="headers" :items="diseases" sort-by="name" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>ANNOTATIONS </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item.annotationsUpdatedAt="{ item }">
        {{ item.annotationsUpdatedAt ? new Date(item.annotationsUpdatedAt).toLocaleString():null }}
    </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top color="orange">
          <template v-slot:activator="{ on }">
            <v-btn icon large color="secondary" @click="editItem(item)" v-on="on">
              <v-icon>mdi-upload-outline</v-icon>
            </v-btn>
          </template>
          <span
            >Upload <strong>{{ item.cohortname }}</strong>
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
    
    <script>
import config from "@/config.js";

import VueFileAgentPlugin from "vue-file-agent";
// eslint-disable-next-line no-unused-vars
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";
const VueFileAgent = VueFileAgentPlugin.VueFileAgent;
// import { getAnnotations } from "@/api/annotations.js";
import { getDiseases } from "@/api/disease.js";
import  ApiIntegrface  from "@/api/api.js"
import store from "@/store";

export default {
  components: { VueFileAgent },

  data: () => ({
    valid: true,
    dialog: false,
    isEditing: false,
    diseases: [],
    fileRecords: [],
    errorMessage:null,
    uploadUrl: config.backendEndpoint + "/api/v1/diseases/",
    headers: [
      { text: "DISEASE", value: "name", class: "accent" },
      { text: "ANOTATIONS LAST UPDATE", value: "annotationsUpdatedAt", class: "accent" },
      { text: "UPLOAD", width: "140px", divider: true, value: "actions", sortable: false, class: "accent" },
    ],
    annotations: [],
    // uploadHeaders: { "Content-Type": "/form-data", Authorization: "Bearer " + store.state.Token },
    uploadHeaders: { "Content-Type": "application/zip", Authorization: "Bearer " + store.state.Token },
    fileRecordsForUpload: [], // maintain an upload queue
    editedIndex: -1,
    selectedDisease: {
      id: 0,
      name: "",
      status: "",
    },
    defaultItem: {
      id: 0,
      name: "",
      status: "",
    },
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },
  // computed:{
  //   allDiseases() {
  //       return this.diseases.map(d => {
  //           let item = d
  //           this.annotations.findIndex(a => a.disease == item.name) == -1 ? item.status = 'NO DATA' :item.status = 'UPLOADED'
  //           return item
  //       } )
  //   }
  // },
  methods: {
    // loadAnnotations() {
    //   const _this = this;
    //   getAnnotations(function (res) {
    //     _this.annotations = res;
    //   });
    // },
    loadDiseases() {
      const _this = this;
      getDiseases((res) => {
        _this.diseases = res//.map(item => {item.annotationsUpdatedAt = new Date(item.annotationsUpdatedAt)});
      });
    },
    initialize() {
      this.loadDiseases();
      // this.loadAnnotations();
    },

    editItem(item) {
      this.selectedDisease = JSON.parse(JSON.stringify(item));
      //   console.log(this.selectedDisease.id)
      //   console.log(item)
      this.dialog = true;
      this.isEditing = true;
    },

    close() {
      this.dialog = false;
      this.errorMessage = null;
      this.fileRecordsForUpload = []; //TODO: Making FileUploader empty?!
      this.fileRecords = [];
      this.loadDiseases();
      this.$nextTick(() => {
        this.selectedDisease = Object.assign({}, this.defaultItem);
        this.isEditing = false;
      });
    },
    onUploadError(err) {
      const rsp = err[0]?.response || null;
      if (rsp.status == 400) {
        this.errorMessage = " Invalid File uploaded! " + JSON.stringify(rsp.data);
        // console.log(rsp.data?.file)
      }
    },
    onUploaded(responses) {
      var succeeded = true;
      for (var rsp of responses) {
        if (rsp.error) {
          console.log("onUploaded", responses);
          succeeded = false;
          break;
        }
      }
      if (succeeded) {
        this.$root.notify.show({ message: "All annotation files imported successfully " });
        this.close();
      }
    },
    uploadFiles: function () {
      const _this = this;
      const api = new ApiIntegrface()
      if(api.isRefreshRequired()){
        // console.log('Refresh is required')
        api.doRefreshToken().then(()=>{
          // console.log('Refreshed')
          var url = _this.uploadUrl + _this.selectedDisease.id + "/upload/" + _this.fileRecordsForUpload[0].name();
          _this.$refs.vueFileAgent.upload(url, _this.uploadHeaders, _this.fileRecordsForUpload);
        })
      } else {
        // console.log('No refresh is needed')
        var url = _this.uploadUrl + _this.selectedDisease.id + "/upload/" + _this.fileRecordsForUpload[0].name();
          _this.$refs.vueFileAgent.upload(url, _this.uploadHeaders, _this.fileRecordsForUpload);
      
      }

      //   _this.close()
      // //TODO:Alert user that all previously uploaded data will be removed
      // updateDatasetStatus(this.datasetId, { status: 20},  function (res){
      // console.log(res)
      // _this.dataset.status.id = 20
      // })
    },
    deleteUploadedFile: function (fileRecord) {
      this.errorMessage = null
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: function (fileRecord) {
      this.errorMessage = null
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      this.errorMessage = null
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  },
  save() {
    // var isValidated = this.$refs.form.validate()
    // if (isValidated){
    //   const _this = this
    //   if(this.isEditing){
    //     updateCohort(this.selectedDisease, this.selectedDisease.id,function(){
    //       _this.loadAnnotations()
    //       _this.close()
    //     }, 'Cohort ' + _this.selectedDisease.name +  ' updated successfully')
    //   }else {
    //     addCohort(this.selectedDisease, function(){
    //       _this.loadAnnotations()
    //       _this.close()
    //     }, 'Cohort ' + _this.selectedDisease.name +  ' added successfully'
    //     )
    //   }
    // }
  },
};
</script>
